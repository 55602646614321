/*!
 * Bootstrap JS
 */
import 'bootstrap';

/*!
 * Bootstrap CSS and custom styles
 */
import '../scss/main.scss';

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 */
import './ie10-viewport-bug-workaround.js';
import * as Highcharts from 'highcharts';

require('jquery-circle-progress');
//require('datatables.net');
//require('datatables.net-bs4');

//regex to check if email valid
const email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const username_regex = /^[^\s]+$/;

/*
$.extend( $.fn.dataTable.defaults, {
    searching: false,
    paging:  false,
    info: false
});

$(document).ready( function () {
    $('.rank-table').DataTable({
        orderMulti: true
    });
});*/

$(document).ready(function () {
    // Offcanvas toggle
    $('[data-toggle="offcanvas"]').click(function () {
        $('.row-offcanvas').toggleClass('active');
        $(".btn-sidebar-open").toggleClass('d-none');
    });

    //highlight nav element
    const currentPageUrl = location.href;
    $('.navbar-nav li a').each(function(){
        $(this).toggleClass('active', currentPageUrl === this.href);
    });
});

const circleProgressElements = $('.circle-progress');
circleProgressElements.circleProgress({
    startAngle: -Math.PI * 0.5,
    size: 100,
    fill: '#569E3D',
    lineCap: 'round'
});

circleProgressElements.filter(".percentage").circleProgress({}).on('circle-animation-progress', function (event, progress, stepValue) {
    var percent = 100 * stepValue;
    $(this).find('strong').html(parseInt(percent) + '<i>%</i>');
});

circleProgressElements.filter(":not(.percentage)").circleProgress({}).on('circle-animation-progress', function (event, progress, stepValue) {
    var val = +(stepValue * $(this).data("max")).toFixed(2);
    $(this).find('strong').html(val);
});

$('#nav-login-form').submit(function (evt) {
    if (!$("#nav-login-form > [name='username']").val() && !$("#nav-login-form > [name='password']").val()) {
        evt.preventDefault();
        location.href = "loginpage";
    }
});

function getNewComments() {
    const newest = $('ul.comment-list').data("newest");
    $.ajax ({
        type: "GET",
        url: "newcomments.php",
        data: {newest: newest},
        cache: false,
        success: function(data) {
            const comments = JSON.parse(data);
            comments.forEach(function(comment) {
                addCommentToList(comment);
            });
        },
        error: function(data) {
        }
    });
}

function getLiveData() {
    $.ajax ({
        type: "GET",
        url: "livedata",
        cache: false,
        success: function(data) {
            const liveData = JSON.parse(data);
            updateMatchData(liveData);
        },
        error: function(data) {
        }
    });
}

function buildNotificationString(homeTeam, awayTeam, scoreHome, scoreAway, scoreHomeOld, scoreAwayOld, homeGoal) {
    var string = "";
    if (scoreHomeOld === scoreAwayOld) {
        if (homeGoal) {
            string = homeTeam + " geht " + scoreHome + ":" + scoreAway + " in Führung gegen " + awayTeam;
        } else {
            string = awayTeam + " geht " + scoreHome + ":" + scoreAway + " in Führung gegen " + homeTeam;
        }
    } else if (scoreHome === scoreAway) {
        if (homeGoal) {
            string = "TOR! " + homeTeam + " gelingt der Ausgleich gegen " + awayTeam + " zum " + scoreHome + ":" + scoreAway;
        } else {
            string = "TOR! " + awayTeam + " gelingt der Ausgleich gegen " + homeTeam + " zum " + scoreHome + ":" + scoreAway;
        }
    } else if (scoreHomeOld > scoreAwayOld && homeGoal) {
        string = "TOR! " + homeTeam + " baut die Führung gegen " + awayTeam + " auf " + scoreHome + ":" + scoreAway + " aus.";
    } else if (scoreHomeOld < scoreAwayOld && !homeGoal) {
        string = "TOR! " + awayTeam + " baut die Führung gegen " + homeTeam + " auf " + scoreHome + ":" + scoreAway + " aus.";
    } else if (scoreHome === scoreAway + 1 && !homeGoal) {
        string = "TOR! " + awayTeam + " erzielt den Anschlusstreffer gegen " + homeTeam + ". Es steht " + scoreHome + ":" + scoreAway;
    } else if (scoreHome + 1 === scoreAway && homeGoal) {
        string = "TOR! " + homeTeam + " erzielt den Anschlusstreffer gegen " + awayTeam + ". Es steht " + scoreHome + ":" + scoreAway;
    } else {
        if (homeGoal) {
            string = "Tor für " + homeTeam;
        } else {
            string = "Tor für " + awayTeam;
        }
        string += "!<br>Im Spiel " + homeTeam + " gegen " + awayTeam + " steht es jetzt " + scoreHome + ":" + scoreAway;
    }

    return string;
}

function notify(text) {
    const notification = $('.notification');
    notification.find('.notification-text').html(text);
    notification.addClass('show');
    setTimeout(function () {
        notification.removeClass('show');
    }, 7500);
}

function updateMatchData(data) {
    var liveIds = [];
    data.forEach(function (match) {
        liveIds.push(match['match']);
        var matchIdClass = ".match-"+match['match'];
        if ($(matchIdClass).length > 0) {
            if (!$(matchIdClass).hasClass("live")) {
                $(matchIdClass).addClass("live");
                if (location.pathname === "/match") {
                    setTimeout(function() {location.reload();}, 1500);
                }
            }

            if (match['penaltyHome'] > -1 && match['penaltyAway'] > -1) {
                $(matchIdClass).addClass("additional");
                $(matchIdClass+' .match-result .home-goals').html(match['penaltyHome']);
                $(matchIdClass+' .match-result .away-goals').html(match['penaltyAway']);
                $(matchIdClass+' .match-result .result-additional .home-additional').html(match['scoreHome']);
                $(matchIdClass+' .match-result .result-additional .away-additional').html(match['scoreAway']);
            } else {
                $(matchIdClass+' .match-result .home-goals').html(match['scoreHome']);
                $(matchIdClass+' .match-result .away-goals').html(match['scoreAway']);
            }

            const homeTeamName = $(matchIdClass+' .match-teams div:first-child p').text();
            const awayTeamName = $(matchIdClass+' .match-teams div:last-child p').text();
            const oldHomeScore = $(matchIdClass).data("scoreHomeOld");
            const oldAwayScore = $(matchIdClass).data("scoreAwayOld");
            if (match['scoreHome'] > oldHomeScore && match['scoreHome'] > 0) {
                notify(buildNotificationString(homeTeamName, awayTeamName, match['scoreHome'], match['scoreAway'], oldHomeScore, oldAwayScore, true));
            }
            if (match['scoreAway'] > oldAwayScore && match['scoreAway'] > 0) {
                notify(buildNotificationString(homeTeamName, awayTeamName, match['scoreHome'], match['scoreAway'], oldHomeScore, oldAwayScore, false));
            }
            if (match['penaltyHome'] > $(matchIdClass).data("penaltyHomeOld") && match['penaltyHome'] > 0) {
                notify(homeTeamName + " erzielt ein Tor zum " + match['penaltyHome'] + ":" + match['penaltyAway'] + " im Elfmeterschießen");
            }
            if (match['penaltyAway'] > $(matchIdClass).data("penaltyAwayOld") && match['penaltyAway'] > 0) {
                notify(awayTeamName + " erzielt ein Tor zum " + match['penaltyHome'] + ":" + match['penaltyAway'] + " im Elfmeterschießen");
            }

            var liveStringText = "";
            if (match["phase"] === 2) {
                liveStringText = "Halbzeit";
            } else if (match["phase"] === 5) {
                liveStringText = "Ende 2. Hälfte";
            } else if (match["phase"] === 7) {
                liveStringText = "Pause Verlängerung";
            } else if (match["phase"] === 20 || match["phase"] === 21) {
                liveStringText = "Elfmeterschießen";
            } else if (match["phase"] === 10) {
                liveStringText = "Ende";
            } else if (match["minuteExtra"] > 0) {
                liveStringText = match["minute"]+"+"+match["minuteExtra"]+"'";
            } else if (match["minute"] > 0) {
                liveStringText = match["minute"]+"'";
            } else {
                liveStringText = "LIVE";
            }
            $(matchIdClass+' .live-string span, '+matchIdClass+' .live-string p').html(liveStringText);
            $(matchIdClass).data("scoreHomeOld", match['scoreHome']);
            $(matchIdClass).data("scoreAwayOld", match['scoreAway']);
            $(matchIdClass).data("penaltyHomeOld", match['penaltyHome']);
            $(matchIdClass).data("penaltyAwayOld", match['penaltyAway']);
        }

    });
    $('.live').each(function () {
        var id = parseInt($(this).attr('id').split("match-")[1]);
        if (liveIds.indexOf(id) === -1) {
            $(this).removeClass('live');
            if (location.pathname === "/match") {
                setTimeout(function() {location.reload();}, 1500);
            }
        }
    });
}

function addCommentToList(comment) {
    const options = {hour: '2-digit', minute:'2-digit'};
    const timestamp = Date.parse(comment['date']['date']);
    if (isNaN(timestamp)) {
        return; // seems to be a problem on mac /iOS /Safari -> bug #75 ?
    }
    const date = new Date(timestamp);
    const list = $('ul.comment-list');
    const newId = "comment-" + comment['id'];
    if (parseInt(comment['user']) === 0) {
        list.prepend("<li class='comment mt-1'><div class='media-body p-1 p-lg-2 text-muted small'>gelöschter Kommentar</li>");
    } else {
        list.prepend("<li id='" + newId + "' style='display: none;' class='comment mt-1'>" +
            "<div class='media-body p-1 p-lg-2" + (comment['isown'] ? " own" : "") + "'>" + comment['comment'] +
            "<h5 class='text-right'><small class='text-muted'><a href='profile?id=" + comment['user'] + "'>" +
            comment['name'] + "</a> am " + date.toLocaleDateString('de-DE') + " um " + date.toLocaleTimeString('de-DE', options) +
            "</small></h5></div></li>");
    }
    list.data("newest", comment['id']); // update newest id
    $("#" + newId).slideToggle();

}

$(function(){
    $(".clickable-row").click(function() {
        window.location = $(this).data("href");
    });
    if ($('ul.comment-list').length > 0) {
        getNewComments();
        setInterval(getNewComments, 3000);
    }
    if ($('.match-details').length > 0) {
        getLiveData();
        setInterval(getLiveData, 3000);
    }
    if ($(location).attr('pathname').startsWith("/loginpage") && $(location).attr('search').startsWith("?register")) {
        $('#collapseTwo').collapse('show');
    }

    $('#version').prop('title', 'frontend built: ' + getBuildTime().toLocaleString("de-DE"));
    $('[data-toggle="tooltip"]').tooltip();

    $(document.body).on("submit", "#bet-form", function(event) {
        var postData = {
            'id': matchID,
            'home-bet': $("input[name='home-bet']").val(),
            'away-bet': $("input[name='away-bet']").val()
        };
        //if ($.isNumeric(postData.home-bet) && $.isNumeric(postData.away-bet)) {
        $.ajax ({
            type: "POST",
            url: "bet.php",
            data: postData,
            dataType: "html",
            encode: true,
            cache: false,
            success: function(data) {
                $("#betSuccess").show().delay(5000).fadeOut();
            },
            error: function(data) {
                console.log(data);
            }
        });
        //}
        event.preventDefault();
    });

    $('form.comment-form').submit(function(event) {
        var postData = {
            'comment': $(this).find("input[name='comment']").val()
        };
        $.ajax ({
            type: "POST",
            url: "addcomment.php",
            data: postData,
            encode: true,
            cache: false,
            success: function() {
                getNewComments();
            },
            error: console.log(postData)
        });
        event.preventDefault();
        this.reset();
    });

    $('#champion-form').submit(function(event) {
        var postData = {
            'champion': $(this).find("select[name='champion']").val()
        };
        $.ajax ({
            type: "POST",
            url: "betchampion.php",
            data: postData,
            encode: true,
            cache: false,
            success: function() {
                $("#betSuccess").fadeIn().delay(5000).fadeOut();
                $('.champion-bet-status').removeClass('alert-info').addClass('alert-success');
                const selected = $('#champion-form').find('select[name="champion"]').find(':selected');
                $('.champion-bet-status').html("Dein aktueller Tipp: <img class='team-flag-inline mx-2' src='resources/flags/" + selected.data('key') + ".svg'>" + selected.text());
            }
        });
        event.preventDefault();
    });

    const accountSaveForm = $('#account-save-form');
    accountSaveForm.submit(function(event) {
        const postData = {
            'save_account': $(this).find("input[name='as-check']").is(':checked'),
            'save_mail': $(this).find("input[name='ms-check']").is(':checked')
        };
        if (accountSaveForm.hasClass("first-settings")) {
            postData['id'] = $("#settings-params").data("id");
            postData['token'] = $("#settings-params").data("token");
            postData['first_settings'] = true;
        }
        $.ajax ({
            type: "POST",
            url: "saveaccsettings.php",
            data: postData,
            encode: true,
            cache: false,
            success: function() {
                if (accountSaveForm.hasClass("first-settings")) {
                    $("#account-save-form").slideToggle();
                    $("#reminder-settings").hide().removeClass("d-none").slideToggle();
                    $(".registration-progress > .progress-bar").attr("aria-valuenow", 75).css("width", "75%");
                } else {
                    accountSaveForm.find(".save-success").fadeIn().delay(5000).fadeOut();
                }
            }
        });
        event.preventDefault();
    });

    const reminderForm = $('#reminder-settings-form');
    reminderForm.submit(function(event) {
        const postData = {
            'enable_reminder': $(this).find("input[name='remind-check']").is(':checked'),
            'remind-time': $(this).find("input[name='remind-time']").val()
        };
        if (reminderForm.hasClass("first-settings")) {
            postData['id'] = $("#settings-params").data("id");
            postData['token'] = $("#settings-params").data("token");
            postData['first_settings'] = true;
        }
        $.ajax ({
            type: "POST",
            url: "saveremindersettings.php",
            data: postData,
            encode: true,
            cache: false,
            success: function() {
                if (reminderForm.hasClass("first-settings")) {
                    $("#reminder-settings-form").slideToggle();
                    $("#login-form-container").hide().removeClass("d-none").slideToggle();
                    $("#jumbotron-progress > h2").text("Fertig!");
                    $("#jumbotron-progress span#login-now-text").text("jetzt");
                    $(".registration-progress > .progress-bar").attr("aria-valuenow", 100).css("width", "100%");
                } else {
                    reminderForm.find(".save-success").fadeIn().delay(5000).fadeOut();
                }
            }
        });
        event.preventDefault();
    });

    $('#range-indicator').html(timeString(reminderForm.find("#remind-time").val()));

    // update range indicator string
    reminderForm.find("#remind-time").on("change mousemove input", function() {
        $('#range-indicator').html(timeString($(this).val()));
    });

    // hide / show reminder time input depending if reminder enabled/disabled
    reminderForm.find("#reminder-checkbox").on("change", function () {
        if($(this).is(':checked')) {
            reminderForm.find("#reminder-range-container").slideDown();
        } else {
            reminderForm.find("#reminder-range-container").slideUp();
        }
    });

    const signUpForm = $('#signup-form');
    signUpForm.find('input#username-signup').on("input change paste", function() {
        if (!username_regex.test($(this).val())) {
            $(this).get(0).setCustomValidity("Benutzername darf keine Leerzeichen enthalten");
            $(this).next().html("Benutzername darf keine Leerzeichen enthalten");
        } else if ($(this).val().length < 4) {
            $(this).get(0).setCustomValidity("Benutzername muss mindestens 4 Zeichen lang sein");
            $(this).next().html("Benutzername muss mindestens 4 Zeichen lang sein");
        } else if ($(this).val().length > 32) {
            $(this).get(0).setCustomValidity("Benutzername darf höchstens 32 Zeichen lang sein");
            $(this).next().html("Benutzername darf höchstens 32 Zeichen lang sein");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const tmp = $(this);
            $.ajax({
                type: "POST",
                url: 'checkregistration.php',
                data: { username: tmp.val() },
                cache: false,
                success: function(data) {
                    const result = JSON.parse(data);
                    if (result["valid"]) {
                        tmp.get(0).setCustomValidity("");
                        tmp.next().html();
                    } else {
                        tmp.get(0).setCustomValidity("Bitte wähle einen anderen Benutzernamen");
                        tmp.next().html("Bitte wähle einen anderen Benutzernamen");
                    }
                },
                error: function(data) {
                    tmp.get(0).setCustomValidity("");
                    tmp.next().html();
                }
            });
        }
        setTimeout($(this).parent().addClass("was-validated"), 1000);
    });

    signUpForm.find('input#password-signup').on("input change paste", function() {
        if ($(this).val().length < 8) {
            $(this).get(0).setCustomValidity("Passwort muss mindestens 8 Zeichen lang sein");
            $(this).next().html("Passwort muss mindestens 8 Zeichen lang sein");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const confirm = signUpForm.find('input#password-confirm-signup');
            if ($(this).val() === confirm.val()) {
                confirm.setCustomValidity("");
                confirm.next().html();
            }
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    signUpForm.find('input#password-confirm-signup').on("input change paste", function() {
        if ($(this).val() === signUpForm.find('input#password-signup').val()) {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
        } else {
            $(this).get(0).setCustomValidity("Passwort-Bestätigung stimmt nicht überein");
            $(this).next().html("Passwort-Bestätigung stimmt nicht überein");
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    signUpForm.find('input#email-signup').on("input change paste", function() {
        if (!email_regex.test($(this).val())) {
            $(this).get(0).setCustomValidity("Keine gültige E-Mail-Adresse");
            $(this).next().html("Keine gültige E-Mail-Adresse");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const tmp = $(this);
            $.ajax({
                type: "POST",
                url: 'checkregistration.php',
                data: {email: tmp.val()},
                cache: false,
                success: function (data) {
                    const result = JSON.parse(data);
                    if (result["valid"]) {
                        tmp.get(0).setCustomValidity("");
                        tmp.next().html();
                    } else {
                        tmp.get(0).setCustomValidity("Bitte wähle eine andere E-Mail-Adresse");
                        tmp.next().html("Bitte wähle eine andere E-Mail-Adresse");
                    }
                },
                error: function (data) {
                    tmp.get(0).setCustomValidity("");
                    tmp.next().html();
                }
            });
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    const changePWForm = $('#change-password-form');
    changePWForm.find('input#new-password').on("input change paste", function() {
        if ($(this).val().length < 8) {
            $(this).get(0).setCustomValidity("Passwort muss mindestens 8 Zeichen lang sein");
            $(this).next().html("Passwort muss mindestens 8 Zeichen lang sein");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const confirm = changePWForm.find('input#new-password-conform');
            if ($(this).val() === confirm.val()) {
                confirm.setCustomValidity("");
                confirm.next().html();
            }
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    changePWForm.find('input#new-password-confirm').on("input change paste", function() {
        if ($(this).val() === changePWForm.find('input#new-password').val()) {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
        } else {
            $(this).get(0).setCustomValidity("Passwort-Bestätigung stimmt nicht überein");
            $(this).next().html("Passwort-Bestätigung stimmt nicht überein");
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    if ($("#rank-history").length > 0) {
        //var myChart = Highcharts.chart('rank-history', {
        var rankHistoryOptions = {
            chart: {
                backgroundColor: null,
                type: 'spline'
            },
            title: {
                text: 'Platzierungsverlauf',
                x: -20
            },
            xAxis: {
                type: 'category',
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                min: 0.8,
                startOnTick: false,
                endOnTick: false,
                tickInterval: 1,
                allowDecimals: false,
                reversed: true,
                title: {
                    text: null,
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },
            legend: {
                enabled: true,
            },
            tooltip: {
                useHTML: true,
                shared: true,
                hideDelay: 1000,
                style: {
                    pointerEvents: 'auto'
                },
                formatter: function() {
                    var s = "Platzierung nach dem Spiel:<br><a href='match?id=" + this.points[0].point.id + "'>" + this.points[0].key + "</a><br>";

                    $.each(this.points, function () {
                        s += "<span style='color:"+ this.color +"'>\u25CF</span> " + this.series.name + ": <b>Platz " + this.y + "</b><br>";
                    });

                    return s;
                },
                positioner: function (w, h, p) {
                    return { x: p.plotX + this.chart.hoverSeries.xAxis.left - w/2, y: 100 };
                }
            },
            series: [{
                name: $("#rank-history").data("user-name"),
                data: [],
                color: $("#rank-history").data("color")
            }, {
                name: "Du",
                data: [],
                color: $("#rank-history").data("own-color")
            }]
        };

        $.ajax({
            url: 'getrankhistory?id=' + $("#rank-history").data("user"),
            dataType: 'json',
            success: function(data) {
                rankHistoryOptions.series[0].data = data['history'];
                if ($("#rank-history").data("own") !== $("#rank-history").data("user")) {
                    $.ajax({
                        url: 'getrankhistory?id=' + $("#rank-history").data("own"),
                        dataType: 'json',
                        success: function(data) {
                            rankHistoryOptions.series[1].data = data['history'];
                            Highcharts.chart('rank-history', rankHistoryOptions);
                        }
                    });
                } else {
                    rankHistoryOptions.series.splice(1, 1);
                    Highcharts.chart('rank-history', rankHistoryOptions);
                }
            }
        });
    }

    var pointStats = $("#point-stats");
    if (pointStats.length > 0) {
        Highcharts.chart('point-stats', {
            chart: {
                type: 'column',
                backgroundColor: null
            },
            title: {
                text: null,
                verticalAlign: "bottom",
                enabled: false
            },
            xAxis: {
                categories: [0, 5, 10, 15, 20],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '%'
                },
            },
            tooltip: {
                enabled: true,
                pointFormat: '{point.y}',
                valueSuffix: ' %',
                headerFormat: null

            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: "",
                data: [Math.round(pointStats.data("p0") * 100),
                    Math.round(pointStats.data("p5") * 100),
                    Math.round(pointStats.data("p10") * 100),
                    Math.round(pointStats.data("p15") * 100),
                    Math.round(pointStats.data("p20") * 100)],
                colorByPoint: true,
                colors: ['#dc3545', '#fd7e14', '#ffc107', '#6610f2', '#28a745'],
                pointPadding: 0,
                groupPadding: 0
            }]
        });
    }
    var championBetChart = $('#champion-bet-chart');
    if (championBetChart.length > 0) {
        var championBetChartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: null
            },
            credits: false,
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '<b>Tipps: {point.y}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    borderWidth: null,
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },
            series: [{
                name: 'Team',
                colorByPoint: true,
                data: []
            }]
        };
        $.ajax({
            url: 'getchampionbetstats.php',
            dataType: 'json',
            success: function(data) {
                $.each(data, function( index, value ) {
                    championBetChartOptions.series[0].data.push({"name": index, "y": value})
                });
                Highcharts.chart('champion-bet-chart', championBetChartOptions);
            }
        });
    }

});

window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
}, false);


/**
 * function to convert minutes (integer value) into a string containing hours and minutes
 * @param minutes
 * @returns {string}
 */
function timeString(minutes) {
    minutes = parseInt(minutes);
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;
    var str = minutes + " min";
    if (hours > 0) {
        str = hours + " h ";
        if (minutes > 0) {
            str += minutes + " min";
        }
    }
    return str;
}

/**
 * Function to retrieve a get parameter from the current document (using location.href)
 *
 * @param parameter {String} Key of the get parameter to retrieve
 */
function _get(parameter) {
    const reg = new RegExp( '[?&]' + parameter + '=([^&#]*)', 'i' );
    var string = reg.exec(window.location.href);
    return string ? string[1] : undefined;
}

function getBuildTime() {
    return new Date(__BUILD_TIME__);
}
